import Keycloak from 'keycloak-js';
import { getApiOrigin, getFullBaseURL } from './core/api-client';

function determineKeycloakUrl() {
  const currentHost = window.location.hostname;
  const currentPort = window.location.port;

  if (
    (currentHost === 'localhost' || currentHost === '127.0.0.1') &&
    currentPort === '3000'
  ) {
    return 'http://127.0.0.1:4589/auth';
  } else {
    return `${getFullBaseURL() || getApiOrigin()}/auth`;
  }
}

export function createKeycloak() {
  return new Keycloak({
    url: determineKeycloakUrl(),
    realm: 'tensorleap',
    clientId: 'tensorleap-client',
  });
}
