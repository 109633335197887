import {
  useState,
  createContext,
  FC,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { GetEnvironmentInfoResponse } from '@tensorleap/api-client';
import api, { getBasePrefixURL } from './api-client';
import useAsyncEffect from './useAsyncEffect';
import { useAuth } from '../auth/AuthContext';

export interface EnvironmentInfoContextParams {
  environmentInfo: GetEnvironmentInfoResponse;
}
const DEFAULT = {
  clientStoragePrefixUrl: '',
  schemaVersion: 0,
  hubDefaultNamespace: 'demo',
  hubUrl: 'https://hub.tensorleap.ai',
  disableDatadogMetrics: true,
};

export const EnvironmentInfoContext = createContext<EnvironmentInfoContextParams>(
  {
    environmentInfo: DEFAULT,
  }
);

export const EnvironmentInfo: FC = ({ children }) => {
  const { user, isUserLoggedIn } = useAuth();

  const [
    environmentInfo,
    setEnvironmentInfo,
  ] = useState<GetEnvironmentInfoResponse>(DEFAULT);

  const fetchEnvironmentInfo = useCallback(async () => {
    try {
      if (!isUserLoggedIn) return;
      const result = await api.getEnvironmentInfo();
      const basename = getBasePrefixURL();
      const clientStoragePrefixUrl = basename
        ? `${basename}${result.clientStoragePrefixUrl}`
        : result.clientStoragePrefixUrl;
      const newEnvironmentInfo = {
        ...result,
        clientStoragePrefixUrl,
      };
      setEnvironmentInfo(newEnvironmentInfo);
      setupDisableDatadogMetrics(result.disableDatadogMetrics);
    } catch (e) {
      console.error(e);
    }
  }, [isUserLoggedIn]);

  useAsyncEffect(fetchEnvironmentInfo, [fetchEnvironmentInfo, user]);

  const value = useMemo<EnvironmentInfoContextParams>(
    () => ({
      environmentInfo,
    }),
    [environmentInfo]
  );

  return (
    <EnvironmentInfoContext.Provider value={value}>
      {children}
    </EnvironmentInfoContext.Provider>
  );
};

export const DISABLE_DATADOG_METRICS_KEY = 'disableDatadogMetrics';
function setupDisableDatadogMetrics(disableDatadogMetrics: boolean) {
  const isDatadogDisabledRawVal = localStorage.getItem(
    DISABLE_DATADOG_METRICS_KEY
  );
  const isDatadogDisabled = isDatadogDisabledRawVal === 'true';

  const needsToUpdateLocalStorage =
    isDatadogDisabledRawVal === null ||
    disableDatadogMetrics !== isDatadogDisabled;
  const needsToReload =
    disableDatadogMetrics !== isDatadogDisabled ||
    (isDatadogDisabledRawVal === null && disableDatadogMetrics === false);

  if (needsToUpdateLocalStorage) {
    localStorage.setItem(
      DISABLE_DATADOG_METRICS_KEY,
      disableDatadogMetrics ? 'true' : 'false'
    );
  }
  if (needsToReload) {
    window.location.reload();
  }
}

export const useEnvironmentInfo = (): EnvironmentInfoContextParams =>
  useContext(EnvironmentInfoContext);
